import Nav from "./Components/Nav";
import "./App.css";
import Body from "./Components/Body";

function App() {
	return (
		<div>
			<Nav></Nav>
			<Body></Body>
		</div>
	);
}

export default App;
