export default function Card({ link, title, heading, Backend, Frontend }) {
	return (
		<div className="card">
			<iframe
				src={link}
				loading="eager"
				title={title}
				width="400rem"
				height="400rem"
			></iframe>
			<div className="card_info">
				<h4>
					<span className="bold">{heading}</span>
				</h4>

				<p>
					<span className="bold">Backend:</span>
					{Backend}
				</p>
				<p>
					<span className="bold">Frontend: </span>
					{Frontend}
				</p>
				<a href={link} target="_blank" rel="noreferrer">
					<button className="pushable">
						<span className="front">👀 Lets see</span>
					</button>
				</a>
			</div>
		</div>
	);
}
