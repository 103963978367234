import "../Styles/Body.css";
import Card from "./Card";
import AboutMe from "./AboutMe";
export default function Body() {
	return (
		<div className="body">
			<AboutMe />
			<h1>Check out my projects!</h1>
			<div className="card_container">
				<Card
					link="https://ncgamestop.netlify.app/"
					title="Games reviews"
					heading="A full stack web app!"
					Backend="Express & PostgreSQL"
					Frontend="React & React Router"
				/>
				<Card
					link="https://u-ft-djkhaled.netlify.app/"
					title="Spotify player"
					heading="Mini Spotify player!"
					Backend="Server side auth w/ Express & Spotify API"
					Frontend="React"
				/>
				<Card
					link="https://www.youtube.com/embed/42jhRv-Ir4Q?start=100"
					title="Find5"
					heading="React native app demo"
					Backend="Firebase, Firebase Auth,
					Cloud Firestore & Expo"
					Frontend="React native"
				/>
				<Card
					link="https://www.youtube.com/embed/8ooAvUuCov0"
					title="Hands on with AWS SQS and Lambda"
					heading="Hands on with AWS SQS and Lambda"
					Backend="SQS Lambda SES API Gateway"
				/>
			</div>

		</div>
	);
}
