import "../Styles/AboutMe.css";
export default function AboutMe() {
	return (
		<div>
			<h1>
				Hi <span className="wave">👋🏽</span>
			</h1>
			<p>I am a full stack software developer</p>
		</div>
	);
}
