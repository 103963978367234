import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
export default function Navigation() {
	return (
		<div>
			<Navbar bg="dark" expand="lg" variant="dark">
				<Container>
					<Navbar.Brand href="/" style={{ fontSize: "2rem" }}>
						<img
							src="https://ca.slack-edge.com/T0CTTF9EW-U02FRSYEZT9-2a1a6e9c3cda-192"
							style={{
								height: "50px",
								borderRadius: "50%",
								objectFit: "cover",
							}}
							alt="Khizar"
						></img>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							<Nav.Link href="https://github.com/khizarIqbal93" target="_blank">
								<img
									src="https://www.freeiconspng.com/thumbs/github-icon/github-icon-9.png"
									style={{ height: "50px" }}
									alt="GitHub"
								></img>
							</Nav.Link>
							<Nav.Link
								href="https://www.linkedin.com/in/khizar--iqbal/"
								target="_blank"
							>
								<img
									src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
									alt="LinkedIn"
									style={{ height: "30px" }}
								></img>
							</Nav.Link>
						</Nav>
						<Nav>
							<Nav.Link
								href="https://www.codewars.com/users/khizariqbal"
								target="_blank"
							>
								<img
									src="https://www.codewars.com/users/khizariqbal/badges/micro"
									alt="code wars"
									style={{ height: "30px" }}
								></img>
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
}
